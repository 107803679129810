// src/components/ProcessCard.jsx
import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assests/ProcessCard.css';

const ProcessCard = ({ serviceName, totalProcesses, completedProcesses, processes }) => {
  const progressPercentage = (completedProcesses / totalProcesses) * 100;

  // Calculer les proportions pour chaque statut
  const calculateStatusPercentages = () => {
    const statusCounts = {
      'En retard': 0,
      'Engagé': 0,
      'Terminé': 0
    };

    processes.forEach((process) => {
      statusCounts[process.status]++;
    });

    return {
      enRetard: (statusCounts['En retard'] / totalProcesses) * 100,
      engage: (statusCounts['Engagé'] / totalProcesses) * 100,
      termine: (statusCounts['Terminé'] / totalProcesses) * 100
    };
  };

  const statusPercentages = calculateStatusPercentages();

  const getStatusColor = (status) => {
    switch (status) {
      case 'En retard':
        return 'bg-danger';
      case 'Engagé':
        return 'bg-warning text-dark';
      case 'Terminé':
        return 'bg-success';
      default:
        return '';
    }
  };

  return (
    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
      <div className="card shadow-sm rounded-3 border-0">
        <div className="card-body">
          <h5 className="card-title fw-bold text-primary">{serviceName}</h5>
          <p className="card-text">
            <span className="text-muted">{completedProcesses}/{totalProcesses}</span> sous-processus achevés
          </p>

          {/* Stacked ProgressBar avec différentes couleurs */}
          <ProgressBar className="mb-3">
            <ProgressBar 
              now={statusPercentages.enRetard} 
              label={`${statusPercentages.enRetard.toFixed(0)}%`} 
              variant="danger" 
            />
            <ProgressBar 
              now={statusPercentages.engage} 
              label={`${statusPercentages.engage.toFixed(0)}%`} 
              variant="warning" 
            />
            <ProgressBar 
              now={statusPercentages.termine} 
              label={`${statusPercentages.termine.toFixed(0)}%`} 
              variant="success" 
            />
          </ProgressBar>

          <div className="mt-3">
            <h6 className="text-secondary">Indicateurs d'état :</h6>
            <ul className="list-inline">
              <li className="list-inline-item">
                <span className="badge bg-danger">En retard</span>
              </li>
              <li className="list-inline-item">
                <span className="badge bg-warning text-dark">Engagé</span>
              </li>
              <li className="list-inline-item">
                <span className="badge bg-success">Terminé</span>
              </li>
            </ul>
          </div>

          <div className="mt-3">
            <h6 className="text-secondary">Liste des processus :</h6>
            <div className="row">
              {processes.map((process, index) => (
                <div key={index} className="col-12 d-flex align-items-center mb-2">
                  <div className={`triangle ${getStatusColor(process.status)}`}></div>
                  <span className="ms-2 text-truncate">{process.name}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProcessCard;
