import React, { useState } from 'react';

const Step5 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Êtes-vous déjà à Calgary ?</label>
        <select
          className="form-control"
          name="a_calgary"
          value={form.a_calgary || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Pays d’origine</label>
        <input
          type="text"
          className="form-control"
          name="pays_origine"
          value={form.pays_origine || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Avez-vous besoin d’offres de billets d’avion ?</label>
        <select
          className="form-control"
          name="offres_billets"
          value={form.offres_billets || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Date prévue d’arrivée</label>
        <input
          type="date"
          className="form-control"
          name="date_arrivee"
          value={form.date_arrivee || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Statut au Canada</label>
        <input
          type="text"
          className="form-control"
          name="statut_canada"
          value={form.statut_canada || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Domaine de travail au Canada</label>
        <input
          type="text"
          className="form-control"
          name="domaine_travail"
          value={form.domaine_travail || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Êtes-vous disposé à travailler pour moins de 20$/h ?</label>
        <select
          className="form-control"
          name="travail_moins_20"
          value={form.travail_moins_20 || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Êtes-vous disposé à faire du bénévolat ?</label>
        <select
          className="form-control"
          name="benevolat"
          value={form.benevolat || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>
    </form>
  );
};

export default Step5;
