import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBed, faBath, faRulerCombined, faMap } from '@fortawesome/free-solid-svg-icons';
import './assests/RealEstatePage.css'; // Importez votre fichier CSS ici

const RealEstatePage = () => {
  const [chambres, setChambres] = useState('');
  const [sallesDeBain, setSallesDeBain] = useState('');
  const [superficie, setSuperficie] = useState('');
  const [prixMax, setPrixMax] = useState(''); // Nouvel état pour le prix maximum
  const [quartier, setQuartier] = useState(''); // Nouvel état pour le quartier
  const [visibleHouses, setVisibleHouses] = useState(10); // Nombre initial de maisons visibles

  const properties = [
    { id: 1, name: 'STUDIOS MODERNE', price: 290.99, beds: 1, baths: 1, area: 50, quartier: 'Kinston City', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 2, name: 'APPARTEMENT LUXUEUX', price: 450.00, beds: 2, baths: 2, area: 80, quartier: 'Kinston City', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 3, name: 'MAISON FAMILIALE', price: 650.50, beds: 3, baths: 2, area: 120, quartier: 'Green Hills', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 4, name: 'VILLA MODERNE', price: 890.75, beds: 4, baths: 3, area: 200, quartier: 'Downtown', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 5, name: 'LOFT URBAIN', price: 370.00, beds: 1, baths: 1, area: 55, quartier: 'Green Hills', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 6, name: 'MAISON CONTEMPORAINE', price: 780.25, beds: 3, baths: 2, area: 150, quartier: 'Suburbs', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 7, name: 'PENTHOUSE EXCLUSIF', price: 1200.00, beds: 1, baths: 1, area: 100, quartier: 'Downtown', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 8, name: 'CHALET DE MONTAGNE', price: 530.50, beds: 4, baths: 3, area: 180, quartier: 'Suburbs', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 9, name: 'MAISON TRADITIONNELLE', price: 400.00, beds: 3, baths: 2, area: 110, quartier: 'Old Town', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
    { id: 10, name: 'MAISON ÉCOLOGIQUE', price: 560.00, beds: 2, baths: 1, area: 75, quartier: 'Green Hills', img: 'https://i.pinimg.com/564x/22/c9/16/22c9162c492596894e718d4ad8f0d889.jpg' },
  ];

  const handleSearch = () => {
    console.log('Chambres:', chambres);
    console.log('Salles de Bain:', sallesDeBain);
    console.log('Superficie:', superficie);
    console.log('Prix Max:', prixMax);
    console.log('Quartier:', quartier);
  };

  const filteredProperties = properties.filter(property => {
    return (
      (chambres ? property.beds >= chambres : true) &&
      (sallesDeBain ? property.baths >= sallesDeBain : true) &&
      (superficie ? property.area >= superficie : true) &&
      (prixMax ? property.price <= prixMax : true) && // Filtre sur le prix maximum
      (quartier ? property.quartier.toLowerCase().includes(quartier.toLowerCase()) : true) // Filtre sur le quartier
    );
  });

  const loadMoreHouses = () => {
    setVisibleHouses(prevVisible => prevVisible + 5); // Charge 5 propriétés supplémentaires à chaque fois
  };

  const handleScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      loadMoreHouses();
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll); // Nettoyage après le défilement
  }, []);

  return (
    <div className="container-fluid my-5">
      <div className="row">
  <div className="d-flex flex-wrap mb-4">
    <div className="input-group me-2 mb-3 col-12 col-md">
      <span className="input-group-text"><FontAwesomeIcon icon={faBed} /></span>
      <input
        type="number"
        className="form-control"
        placeholder="Chambres"
        value={chambres}
        onChange={(e) => setChambres(e.target.value)}
        min={0}
      />
    </div>
    <div className="input-group me-2 mb-3 col-12 col-md">
      <span className="input-group-text"><FontAwesomeIcon icon={faBath} /></span>
      <input
        type="number"
        className="form-control"
        placeholder="Salles de Bain"
        value={sallesDeBain}
        onChange={(e) => setSallesDeBain(e.target.value)}
        min={0}
      />
    </div>
    <div className="input-group me-2 mb-3 col-12 col-md">
      <span className="input-group-text"><FontAwesomeIcon icon={faRulerCombined} /></span>
      <input
        type="number"
        className="form-control"
        placeholder="Superficie (m²)"
        value={superficie}
        onChange={(e) => setSuperficie(e.target.value)}
        min={0}
      />
    </div>
    <div className="input-group me-2 mb-3 col-12 col-md">
      <span className="input-group-text">$</span>
      <input
        type="number"
        className="form-control"
        placeholder="Prix Maximum"
        value={prixMax}
        onChange={(e) => setPrixMax(e.target.value)}
        min={0}
      />
    </div>
    <div className="input-group me-2 mb-3 col-12 col-md">
      <span className="input-group-text"><FontAwesomeIcon icon={faMap} /></span>
      <input
        type="text"
        className="form-control"
        placeholder="Quartier"
        value={quartier}
        onChange={(e) => setQuartier(e.target.value)}
      />
    </div>
  </div>
</div>

      

      {/* Property Cards Section */}
      <div className="row">
        {filteredProperties.slice(0, visibleHouses).map(property => (
          <div key={property.id} className="col-md-4 mb-4">
            <div className="card">
              <img src={property.img} className="card-img-top" alt={property.name} />
              <div className="card-body">
                <div className='d-flex justify-content-between'>
                  <h5 className="card-titl">{property.name}</h5>
                  <p className="card-text orange">${property.price.toFixed(2)}</p>
                </div>
                <span className='d-flex '>
                  <p className="card-text m-1"><FontAwesomeIcon icon={faBed} /> {property.beds} Chambres</p>
                  <p className="card-text m-1"><FontAwesomeIcon icon={faBath} /> {property.baths} Salles de Bain</p>
                  <p className="card-text m-1"><FontAwesomeIcon icon={faRulerCombined} /> {property.area} m²</p>
                </span>
                
                <hr />
                <p className="card-text"><FontAwesomeIcon icon={faMap} /> {property.quartier}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      {visibleHouses < filteredProperties.length && (
        <div className="text-center mt-4">
          <button className="btn btn-secondary" onClick={loadMoreHouses}>
            Voir plus
          </button>
        </div>
      )}
    </div>
  );
};

export default RealEstatePage;
