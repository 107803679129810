import React from 'react';
import './assests/Stepper.css';

const Stepper = ({ currentStep }) => {
  const steps = [
    'Détails Perso',
    'Détails Pro',
    'État Civil',
    'Enfants',
    'Arrivée',
    'Logement',
    'Supplémentaires'
  ];

  return (
    <div className="d-flex justify-content-between mb-4">
      {steps.map((step, index) => {
        const stepNumber = index + 1; // Numéro de l'étape

        return (
          <div key={index} className="text-center stepper-item">
            <div
              className={`stepper-circle ${
                stepNumber === currentStep ? 'active' : ''
              } ${stepNumber < currentStep ? 'completed' : ''}`} // Ajout de la classe 'completed'
            >
              {stepNumber}
            </div>
            <p className="mt-2">{step}</p>
          </div>
        );
      })}
    </div>
  );
};

export default Stepper;
