import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './components/AuthContext';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import PreDeparture from './components/PreDeparture';
import Accueil from './components/Accueil';
import AccompagnementAdministratif from './components/AccompagnementAdministratif';
import AccompagnementProfessionnel from './components/AccompagnementProfessionnel';
import AccompagnementSocial from './components/AccompagnementSocial';
import Suivi from './components/Suivi';
import Logements from './components/Logements'
import RealEstatePage from './components/RealEstatePage';
import AdminPage from './components/AdminPage';
import Appointment from './components/appointment/appointment';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path='/realestat' element={<RealEstatePage/>}/>
          <Route path='/addhouse' element={<AdminPage/>}/>
          <Route path='/appointment' element={<Appointment/>}/>
          {/* Route protégée */}
          <Route 
            path="/dashboard" 
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            } 
          />
          <Route 
            path='/pre-depart'
            element={
              <ProtectedRoute>
                <PreDeparture />
              </ProtectedRoute>
            }
            />
            <Route 
              path='/accueil'
              element={
                <ProtectedRoute>
                  <Accueil />
                </ProtectedRoute>
              }
              />
              <Route 
                path='/Logements'
                element={
                  <ProtectedRoute>
                    <Logements />
                  </ProtectedRoute>
                }
                />
                <Route 
                  path='/accompagnement-administratif'
                  element={
                    <ProtectedRoute>
                      <AccompagnementAdministratif/>
                    </ProtectedRoute>
                  }
                  />
                  <Route 
                    path='/accompagnement-professionnel'
                    element={
                      <ProtectedRoute>
                        <AccompagnementProfessionnel/>
                      </ProtectedRoute>
                    }
                    />
                    <Route
                      path='/accompagnement-social'
                      element={
                        <ProtectedRoute>
                          <AccompagnementSocial/>
                        </ProtectedRoute>
                      }
                      />
                      <Route 
                        path='/suivi'
                        element={
                          <ProtectedRoute>
                            <Suivi/>
                          </ProtectedRoute>
                        }
                        />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
