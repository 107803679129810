import React, { useState } from 'react';

const Step4 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Avez-vous des enfants ?</label>
        <select
          className="form-control"
          name="enfants"
          value={form.enfants || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      {form.enfants === 'oui' && (
        <>
          <div className="mb-3">
            <label className="form-label">Nombre d'enfants</label>
            <input
              type="number"
              className="form-control"
              name="nombre_enfants"
              value={form.nombre_enfants || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Âge des enfants (séparer par une virgule)</label>
            <input
              type="text"
              className="form-control"
              name="age_enfants"
              value={form.age_enfants || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Sexe des enfants (séparer par une virgule)</label>
            <input
              type="text"
              className="form-control"
              name="sexe_enfants"
              value={form.sexe_enfants || ''}
              onChange={handleChange}
            />
          </div>
        </>
      )}
    </form>
  );
};

export default Step4;
