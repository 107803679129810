import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

import Sidebar from './SidebarAdmin';
import MainContent from './MainContent';

const AdminPage = () => {
  return (
    <div className="d-flex flex-column">
      
      <div className="d-flex flex-row">
        <Sidebar />
        <MainContent />
      </div>
    </div>
  );
};

export default AdminPage;
