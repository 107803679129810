import React, { useState } from 'react';

const Step3 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Êtes-vous marié(e) ?</label>
        <select
          className="form-control"
          name="mariage"
          value={form.mariage || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      {form.mariage === 'oui' && (
        <>
          <div className="mb-3">
            <label className="form-label">Prénom conjoint(e)</label>
            <input
              type="text"
              className="form-control"
              name="prenom_conjoint"
              value={form.prenom_conjoint || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Nom conjoint(e)</label>
            <input
              type="text"
              className="form-control"
              name="nom_conjoint"
              value={form.nom_conjoint || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Âge conjoint(e)</label>
            <input
              type="number"
              className="form-control"
              name="age_conjoint"
              value={form.age_conjoint || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Domaine d'activité conjoint(e)</label>
            <input
              type="text"
              className="form-control"
              name="domaine_conjoint"
              value={form.domaine_conjoint || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Années d'expérience conjoint(e)</label>
            <input
              type="number"
              className="form-control"
              name="experience_conjoint"
              value={form.experience_conjoint || ''}
              onChange={handleChange}
            />
          </div>

          <div className="mb-3">
            <label className="form-label">Permis de conduire conjoint(e) ?</label>
            <select
              className="form-control"
              name="permis_conjoint"
              value={form.permis_conjoint || ''}
              onChange={handleChange}
            >
              <option value="">Choisir</option>
              <option value="oui">Oui</option>
              <option value="non">Non</option>
            </select>
          </div>
        </>
      )}
    </form>
  );
};

export default Step3;
