import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    // Si l'utilisateur n'est pas connecté, on le redirige vers la page de login
    return <Navigate to="/" />;
  }

  // Sinon, on affiche la route protégée
  return children;
};

export default ProtectedRoute;
