import React from 'react';
import { FaBell, FaBars } from 'react-icons/fa'; 
import './assests/Dashboard.css';

const Header = ({ pageTitle, username, toggleSidebar }) => {
  return (
    <header className="header d-flex justify-content-between align-items-center p-3 bg-light shadow-sm">
      {/* Button for opening the sidebar on mobile */}
      <button className="btn btn-primary d-md-none" onClick={toggleSidebar}>
        <FaBars />
      </button>

      <div className="page-title">
        <h4>{pageTitle}</h4>
      </div>

      <div className="user-section d-flex align-items-center">
        <FaBell size={24} className="me-4 text-muted" />
        <div className="user-info d-flex align-items-center">
          <img 
            src="https://via.placeholder.com/40" 
            alt="User" 
            className="rounded-circle me-2" 
          />
          <span>{username}</span>
        </div>
      </div>
    </header>
  );
};

export default Header;
