import React, { useState } from 'react';

const Step2 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Domaine d'activité</label>
        <input
          type="text"
          className="form-control"
          name="domaine_activite"
          value={form.domaine_activite || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Nombre d’années d’expérience</label>
        <input
          type="number"
          className="form-control"
          name="annees_experience"
          value={form.annees_experience || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Avez-vous un permis de conduire ?</label>
        <select
          className="form-control"
          name="permis_conduire"
          value={form.permis_conduire || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      {form.permis_conduire === 'oui' && (
        <div className="mb-3">
          <label className="form-label">Catégorie du permis</label>
          <input
            type="text"
            className="form-control"
            name="categorie_permis"
            value={form.categorie_permis || ''}
            onChange={handleChange}
          />
        </div>
      )}
    </form>
  );
};

export default Step2;
