import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assests/Login.css'; // Assurez-vous d'inclure votre fichier CSS personnalisé
import Logo from './assests/images/logo.png'; // Importez le logo

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Simulez une authentification basique
    if (email === 'test@example.com' && password === 'password') {
      login(); // Met à jour l'état d'authentification
      navigate('/dashboard'); // Redirige vers le tableau de bord
    } else {
      setErrorMessage('Identifiants incorrects. Veuillez réessayer.');
    }
  };

  return (
    <div className="login-container d-flex flex-column align-items-center justify-content-center min-vh-100">
      <img src={Logo} alt="Logo HANA" className="mb-5" /> {/* Logo */}
      <h5 className="mb-3">Merci d’entrer vos informations de connexion</h5>
      
      {/* Affichage du message d'erreur en cas d'échec */}
      {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}

      <form onSubmit={handleSubmit} className="w-100" style={{ maxWidth: '400px' }}>
        <div className="form-group mb-3">
          <label htmlFor="email" className="visually-hidden">Adresse email</label>
          <input
            type="email"
            className="form-control"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Adresse email"
            required
          />
        </div>
        <div className="form-group mb-3">
          <label htmlFor="password" className="visually-hidden">Mot de passe</label>
          <input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Mot de passe"
            required
          />
        </div>

        <button type="submit" className="btn btn-primary w-100">Connexion</button>
      </form>

      <p className="mt-3">
        Vous n’avez pas de compte ? <a href="/register" className="text-decoration-none">Inscrivez-vous</a>.
      </p>
    </div>
  );
};

export default Login;
