import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faTh } from '@fortawesome/free-solid-svg-icons';

const Sidebar = () => {
  return (
    <nav className="col-md-2 d-none d-md-block bg-light sidebar">
      <div className="sidebar-sticky">
        <h1 className="text-center mt-4"><FontAwesomeIcon icon={faTh} /> Dashboard</h1>
        <ul className="nav flex-column">
          <li className="nav-item">
            <a className="nav-link active" href="#">
              <FontAwesomeIcon icon={faHome} /> Accueil
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              <FontAwesomeIcon icon={faPlus} /> Ajouter une Maison
            </a>
          </li>
          {/* Ajoutez d'autres liens de navigation ici */}
        </ul>
      </div>
    </nav>
  );
};

export default Sidebar;
