import React from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaPlane, FaFileAlt, FaBuilding, FaSuitcase, FaHandsHelping, FaChartLine, FaDoorOpen } from 'react-icons/fa';
import Logo from './assests/images/logo.png';
import './assests/Dashboard.css';

const SideBar = ({ isOpen, toggleSidebar }) => {
  const handleLinkClick = () => {
    // Ferme le menu sur mobile une fois qu'un lien est sélectionné
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  return (
    <div className={`sidebar bg-light flex-column flex-shrink-0 p-3 shadow ${isOpen ? 'd-block' : 'd-none d-md-block'}`} style={{ width: '280px', transition: 'all 0.3s ease' }}>
      {/* Close button for mobile */}
      <button className="btn-close d-md-none" onClick={toggleSidebar}></button>

      {/* Logo */}
      <div className="logos text-center mb-4">
        <img src={Logo} width={80} alt='Hana' />
      </div>

      {/* Navigation */}
      <ul className="nav flex-column">
        <li className="nav-item mb-3">
          <Link to="/dashboard" className="nav-link text-color" onClick={handleLinkClick}>
            <FaHome className="me-2 sidebar-icon" /> Tableau de bord
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/pre-depart" className="nav-link text-color" onClick={handleLinkClick}>
            <FaPlane className="me-2 sidebar-icon" /> Pré-départ
          </Link>
        </li>
        <li className='nav-item mb-3'>
          <Link to="/accueil" className='nav-link text-color' onClick={handleLinkClick}>
            <FaDoorOpen className='me-2 sidebar-icon' /> Accueil et bienvenue
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/logements" className="nav-link text-color" onClick={handleLinkClick}>
            <FaBuilding className="me-2 sidebar-icon" /> Logements
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/accompagnement-administratif" className="nav-link text-color" onClick={handleLinkClick}>
            <FaFileAlt className="me-2 sidebar-icon" /> Accompagnement Administratif
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/accompagnement-professionnel" className="nav-link text-color" onClick={handleLinkClick}>
            <FaSuitcase className="me-2 sidebar-icon" /> Accompagnement Professionnel
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/accompagnement-social" className="nav-link text-color" onClick={handleLinkClick}>
            <FaHandsHelping className="me-2 sidebar-icon" /> Accompagnement Social
          </Link>
        </li>
        <li className="nav-item mb-3">
          <Link to="/suivi" className="nav-link text-color" onClick={handleLinkClick}>
            <FaChartLine className="me-2 sidebar-icon" /> Suivi
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
