import React, { useState } from 'react';

const Step6 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Recherchez-vous un logement ?</label>
        <select
          className="form-control"
          name="recherche_logement"
          value={form.recherche_logement || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Combien de chambres ?</label>
        <input
          type="number"
          className="form-control"
          name="nombre_chambres"
          value={form.nombre_chambres || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Loyer maximum souhaitable ?</label>
        <input
          type="number"
          className="form-control"
          name="loyer_max"
          value={form.loyer_max || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Auriez-vous besoin de conversion en $CAD ?</label>
        <select
          className="form-control"
          name="conversion_cad"
          value={form.conversion_cad || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Envisagez-vous acheter une voiture d'ici 6 mois ?</label>
        <select
          className="form-control"
          name="achat_voiture"
          value={form.achat_voiture || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Envisagez-vous acheter une maison d'ici 1 an ?</label>
        <select
          className="form-control"
          name="achat_maison"
          value={form.achat_maison || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="oui">Oui</option>
          <option value="non">Non</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Vos Hobbies</label>
        <input
          type="text"
          className="form-control"
          name="hobbies"
          value={form.hobbies || ''}
          onChange={handleChange}
        />
      </div>
    </form>
  );
};

export default Step6;
