import React, { useState } from 'react';
import Stepper from './Stepper';
import Step1 from './Step1';  
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Review from './Review';
import './assests/Stepper.css'
import Logo from './assests/images/logo.png'

const Register = () => {
  const [currentStep, setCurrentStep] = useState(1);  
  const [formData, setFormData] = useState({
    step1: {}, step2: {}, step3: {}, step4: {}, step5: {}, step6: {}, step7: {}
  });

  const nextStep = () => setCurrentStep((prev) => prev + 1);
  const prevStep = () => setCurrentStep((prev) => prev - 1);

  const updateFormData = (step, data) => {
    setFormData({ ...formData, [step]: data });
  };

  const steps = [
    <Step1 data={formData.step1} updateData={(data) => updateFormData('step1', data)} />,
    <Step2 data={formData.step2} updateData={(data) => updateFormData('step2', data)} />,
    <Step3 data={formData.step3} updateData={(data) => updateFormData('step3', data)} />,
    <Step4 data={formData.step4} updateData={(data) => updateFormData('step4', data)} />,
    <Step5 data={formData.step5} updateData={(data) => updateFormData('step5', data)} />,
    <Step6 data={formData.step6} updateData={(data) => updateFormData('step6', data)} />,
    <Step7 data={formData.step7} updateData={(data) => updateFormData('step7', data)} />,
    <Review formData={formData} />
  ];

  return (
    <div className="container d-flex justify-content-center align-items-center  mt-5 pt-5">
      <div className="form-box p-5 shadow">
        <div className="text-center">
          <img src={Logo} alt="Logo" width={150} className="logo mb-4" />
          <h4 className="mb-4 title">Merci d’entrer vos informations</h4>
        </div>
        <Stepper currentStep={currentStep} />
        <div className="mt-4">{steps[currentStep - 1]}</div>
        <div className="d-flex justify-content-between mt-2">
          <button
            className="btn btn-secondary"
            onClick={prevStep}
            disabled={currentStep === 1}
          >
            Précédent
          </button>
          {currentStep < 8 ? (
            <button className="btn btn-primary" onClick={nextStep}>
              Suivant
            </button>
          ) : (
            <button className="btn btn-success" onClick={() => alert('Formulaire soumis')}>
              Soumettre
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Register;
