import React, { useState } from 'react';

const Step1 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Civilité</label>
        <select
          className="form-control"
          name="civilite"
          value={form.civilite || ''}
          onChange={handleChange}
        >
          <option value="">Choisir</option>
          <option value="Monsieur">Monsieur</option>
          <option value="Madame">Madame</option>
          <option value="Mademoiselle">Mademoiselle</option>
        </select>
      </div>

      <div className="mb-3">
        <label className="form-label">Prénom Requérant principal</label>
        <input
          type="text"
          className="form-control"
          name="prenom"
          value={form.prenom || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Nom Requérant principal</label>
        <input
          type="text"
          className="form-control"
          name="nom"
          value={form.nom || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Âge</label>
        <input
          type="number"
          className="form-control"
          name="age"
          value={form.age || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Téléphone</label>
        <input
          type="text"
          className="form-control"
          name="telephone"
          value={form.telephone || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Adresse e-mail</label>
        <input
          type="email"
          className="form-control"
          name="email"
          value={form.email || ''}
          onChange={handleChange}
        />
      </div>
    </form>
  );
};

export default Step1;
