// src/components/ProcessPieChart.jsx
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
ChartJS.register(ArcElement, Tooltip, Legend);

const ProcessPieChart = () => {
  const data = {
    labels: ['Service 1', 'Service 2', 'Service 3', 'Service 4'],
    datasets: [
      {
        label: 'Progression des services',
        data: [20, 30, 25, 25], // Les pourcentages pour chaque service
        backgroundColor: ['#007bff', '#28a745', '#ffc107', '#dc3545'], // Couleurs différentes pour chaque service
        borderColor: ['#fff'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    cutout: '70%', // Donut styling
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
    },
  };

  return <Doughnut data={data} options={options} />;
};

export default ProcessPieChart;
