import Webcam from "react-webcam";

import React, { useCallback, useRef, useState } from 'react';

import captureScreen from '../../../components/assests/icones/capture_screen.png'
import driverLicence from '../../../components/assests/icones/driver_licence.jpeg'
import driverLicence2 from '../../../components/assests/icones/capture_screen.jpeg'
import resetImage from '../../../components/assests/icones/reset.png'
import cameraImage from '../../../components/assests/icones/camera.png'

const CustomWebcam = ({imgSrc, setImgSrc, labelText, type}) => {

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";
    const [resetImg, setResetImg] = useState(true);

    const webcamRef = useRef(null);
    const videoConstraints = {
        //facingMode: FACING_MODE_USER
        facingMode: FACING_MODE_ENVIRONMENT,
        aspectRatio: 1
      };

        // create a capture function
        const capture = useCallback(() => {
            const imageSrc = webcamRef.current.getScreenshot();

            setImgSrc(imageSrc);
        }, [webcamRef]);

        const retake = () => {
            setImgSrc(null);
        };

        const handleSetResetImg = useCallback(() => {
          setResetImg(false);
          navigator.getUserMedia({audio:false,video:false}, function(stream) {
              stream.getTracks().forEach(x=>x.stop());
            }, err=>console.log(err));
      }, []);

    return (
        <>
        <label className="fs-6">{labelText}</label>
        {
            resetImg?
            <img src={type==='recto'?driverLicence:driverLicence2} className="border rounded w-100 object-fit-cover" alt="driverLicence" />
            :
            imgSrc ? (
                <img src={imgSrc} className="border rounded w-100 object-fit-cover" alt="webcam" />
              ) : (
                <Webcam
                  height={300}
                  width={200}
                  className="w-100"
                  ref={webcamRef}
                  mirrored={false}
                  screenshotFormat="image/png"
                  screenshotQuality={0.8}
                  videoConstraints={videoConstraints}
                />
              )         
        }

        <div className="hstack g-2 d-flex justify-content-center">
            
            {resetImg?
                <button className="btn btn-white" type="button" onClick={handleSetResetImg}><img src={cameraImage} alt="capture" width={30} height={30} /></button>
                :
                imgSrc ? (
                    <button className="btn btn-white" type="button" onClick={retake}><img src={resetImage} alt="reset" width={30} height={30} /></button>
                    ) : (
                    <button className="btn btn-white" type="button" onClick={capture}><img src={captureScreen} alt="capture" width={30} height={30} /></button>
                    )
            }
      </div>
        </>
    );
}

export default CustomWebcam;
