import React, { useState } from 'react';
import './assests/Dashboard.css';
import ProcessPieChart from './ProcessPieChart';
import ServiceBarChart from './ServiceBarChart';
import ProcessCard from './ProcessCard';
import Sidebar from './SideBar';
import Header from './Header';

const Dashboard = () => {
  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  const preDepartureProcesses = [
    { name: 'Réservation du vol', status: 'En retard' },
    { name: 'Préparation des documents', status: 'Engagé' },
    { name: 'Formation en anglais', status: 'Terminé' },
    // Autres processus...
  ];

  const adminSupportProcesses = [
    { name: 'Documents administratifs', status: 'Terminé' },
    { name: 'Choix d’un forfait téléphonique', status: 'En retard' },
    { name: 'Choix d’une école', status: 'Engagé' },
    // Autres processus...
  ];

  return (
    <div className="wrapper flex">
        <div className="">
                {/* Sidebar */}
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />

            
            <div className="contente">
                
                <Header pageTitle="Tableau de bord" username="username" toggleSidebar={toggleSidebar} />

                 
                <div className="p-3">
                <div className="row mb-4">
                    <div className="col-lg-4 col-md-4 col-sm-6">
                    <div className="card">
                        <div className="p-2">
                        <h5>État des Processus</h5>
                        <h4 className="card-text">4 SERVICES</h4>
                        <ProcessPieChart />
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6">
                    <div className="card">
                        <div className="p-2">
                        <h5 className="card-title">Statistique 2</h5>
                        <ServiceBarChart />
                        </div>
                    </div>
                    </div>
                </div>

               
                <div className="row">
                    <ProcessCard
                        serviceName="Service Pré-départ"
                        totalProcesses={8}
                        completedProcesses={5}
                        processes={preDepartureProcesses}
                    />
                    
                    <ProcessCard
                        serviceName="Accompagnement Administratif"
                        totalProcesses={4}
                        completedProcesses={2}
                        processes={adminSupportProcesses}
                    />
                    
                </div>
                </div>
            </div>
        </div>
       
    </div>
  );
};

export default Dashboard;
