import React from 'react';
import './assests/Checklist.css'; // Assurez-vous d'avoir le fichier CSS pour le style

const Checklist = ({ processes }) => {
  const getStatusClass = (status) => {
    switch (status) {
      case 'Terminé':
        return 'checklist-item checklist-item-completed'; // Style vert pour les processus terminés
      case 'Engagé':
        return 'checklist-item checklist-item-in-progress'; // Style pour processus en cours
      case 'En retard':
        return 'checklist-item checklist-item-late'; // Style pour processus en retard
      default:
        return 'checklist-item';
    }
  };

  return (
    <div className="checklist col-lg-6 col-md-6 col-sm-12">
      <ul className="list-group">
        {processes.map((process, index) => (
          <li key={index} className={`${getStatusClass(process.status)} d-flex justify-content-between align-items-center mb-2 p-2`}>
            <div className="d-flex align-items-center">
              {process.status === 'Terminé' ? (
                <span className="checklist-icon">&#10003;</span> // Check mark for completed processes
              ) : (
                <span className="checklist-icon">&#9888;</span> // Warning symbol for others
              )}
              <span className="ms-2">{process.name}</span>
            </div>
            <span className="badge status-badge ms-auto">{process.status}</span> {/* Status on the right */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Checklist;
