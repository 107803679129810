// src/components/ServiceBarChart.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ServiceBarChart = () => {
  const data = {
    labels: ['Service 1', 'Service 2', 'Service 3', 'Service 4'],
    datasets: [
      {
        label: 'Progression (%)',
        data: [70, 50, 90, 40], // Les pourcentages de progression
        backgroundColor: ['#007bff', '#28a745', '#ffc107', '#dc3545'],
        borderColor: ['#fff'],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100, // Limiter l'axe Y à 100 pour cent
      },
    },
  };

  return (
    <div>
      <h4>Progression des Services</h4>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ServiceBarChart;
