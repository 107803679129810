import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faPlus, faTh } from '@fortawesome/free-solid-svg-icons';
import Header from './HeaderAdmin';
const MainContent = () => {
  const [property, setProperty] = useState({
    name: '',
    price: '',
    beds: '',
    baths: '',
    area: '',
    neighborhood: '',
    img: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProperty({ ...property, [name]: value });
  };

  const handleImageChange = (e) => {
    setProperty({ ...property, img: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    for (const key in property) {
      formData.append(key, property[key]);
    }

    // Logique pour ajouter la maison
    console.log('Maison ajoutée:', property);

    // Réinitialiser le formulaire
    setProperty({
      name: '',
      price: '',
      beds: '',
      baths: '',
      area: '',
      neighborhood: '',
      img: null,
    });
  };

  return (
    <main className="col-md-9 ms-sm-auto col-lg-10 px-4">
       
      <h2 className="mt-4"><FontAwesomeIcon icon={faPlus} /> Ajouter une Maison</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="name" className="form-label">Nom</label>
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            value={property.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="price" className="form-label">Prix</label>
          <input
            type="number"
            className="form-control"
            id="price"
            name="price"
            value={property.price}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="beds" className="form-label">Nombre de Chambres</label>
          <input
            type="number"
            className="form-control"
            id="beds"
            name="beds"
            value={property.beds}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="baths" className="form-label">Nombre de Salles de Bain</label>
          <input
            type="number"
            className="form-control"
            id="baths"
            name="baths"
            value={property.baths}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="area" className="form-label">Superficie (m²)</label>
          <input
            type="number"
            className="form-control"
            id="area"
            name="area"
            value={property.area}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="neighborhood" className="form-label">Quartier</label>
          <input
            type="text"
            className="form-control"
            id="neighborhood"
            name="neighborhood"
            value={property.neighborhood}
            onChange={handleChange}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="img" className="form-label">Téléversez une image</label>
          <input
            type="file"
            className="form-control"
            id="img"
            name="img"
            onChange={handleImageChange}
            accept="image/*"
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Ajouter la Maison</button>
      </form>
    </main>
  );
};

export default MainContent;
