import React from 'react';

const Review = ({ formData }) => {
  const steps = [
    {
      title: 'Détails Perso',
      questions: [
        { question: 'Civilité', answer: formData.step1.civilite },
        { question: 'Prénom Requérant principal', answer: formData.step1.prenom },
        { question: 'Nom Requérant principal', answer: formData.step1.nom },
        { question: 'Âge', answer: formData.step1.age },
        { question: 'Téléphone', answer: formData.step1.telephone },
        { question: 'Adresse e-mail', answer: formData.step1.email },
      ]
    },
    {
      title: 'Détails Pro',
      questions: [
        { question: 'Domaine d\'activité', answer: formData.step2.domaine },
        { question: 'Nombre d’années d’expérience', answer: formData.step2.anneesExperience },
        { question: 'Avez-vous un permis de conduire ?', answer: formData.step2.permisConduire ? 'Oui' : 'Non' },
        { question: 'Catégorie du permis', answer: formData.step2.categoriePermis },
      ]
    },
    {
      title: 'État Civil',
      questions: [
        { question: 'Êtes-vous mariés ?', answer: formData.step3.mariage ? 'Oui' : 'Non' },
        { question: 'Prénom conjoint(e)', answer: formData.step3.prenomConjoint },
        { question: 'Nom conjoint(e)', answer: formData.step3.nomConjoint },
        { question: 'Âge', answer: formData.step3.ageConjoint },
        { question: 'Domaine d\'activités', answer: formData.step3.domaineActivite },
        { question: 'Année d\'expérience', answer: formData.step3.anneesExperience },
        { question: 'Permis de conduire', answer: formData.step3.permisConduire ? 'Oui' : 'Non' },
      ]
    },
    {
      title: 'Enfants',
      questions: [
        { question: 'Avez-vous des enfants ?', answer: formData.step4.enfants ? 'Oui' : 'Non' },
        { question: 'Nombre d’enfants', answer: formData.step4.nombreEnfants },
        { question: 'Âge des enfants', answer: formData.step4.ageEnfants },
        { question: 'Sexe des enfants', answer: formData.step4.sexeEnfants },
      ]
    },
    {
      title: 'Arrivée',
      questions: [
        { question: 'Êtes-vous déjà à Calgary ?', answer: formData.step5.dejaCalgary ? 'Oui' : 'Non' },
        { question: 'Pays d’origine', answer: formData.step5.paysOrigine },
        { question: 'Avez-vous besoin d’offres de billets d’avion ?', answer: formData.step5.billetsAvion ? 'Oui' : 'Non' },
        { question: 'Date prévue d’arrivée', answer: formData.step5.dateArrivee },
        { question: 'Statut au Canada', answer: formData.step5.statuts },
        { question: 'Domaine de travail souhaité', answer: formData.step5.domaineTravail },
        { question: 'Disposé à travailler pour moins de 20$/h ?', answer: formData.step5.travailMoins20 ? 'Oui' : 'Non' },
        { question: 'Disposé à faire du bénévolat ?', answer: formData.step5.benevolat ? 'Oui' : 'Non' },
      ]
    },
    {
      title: 'Logement',
      questions: [
        { question: 'Recherchez-vous un logement ?', answer: formData.step6.rechercheLogement ? 'Oui' : 'Non' },
        { question: 'Combien de chambres ?', answer: formData.step6.combienChambres },
        { question: 'Loyer maximum souhaitable ?', answer: formData.step6.loyerMax },
        { question: 'Besoin de conversion en $CAD ?', answer: formData.step6.conversionCAD ? 'Oui' : 'Non' },
        { question: 'Envisagez-vous acheter une voiture ?', answer: formData.step6.acheterVoiture ? 'Oui' : 'Non' },
        { question: 'Envisagez-vous acheter une maison ?', answer: formData.step6.acheterMaison ? 'Oui' : 'Non' },
        { question: 'Vos Hobbies', answer: formData.step6.hobbies },
      ]
    },
    {
      title: 'Supplémentaires',
      questions: [
        { question: 'Comment avez-vous entendu parler de nous ?', answer: formData.step7.source },
        { question: 'Loyer maximum souhaitable ?', answer: formData.step7.loyerMax },
        { question: 'Préférence de langue', answer: formData.step7.langue },
        { question: 'Votre niveau d\'anglais', answer: formData.step7.niveauAnglais },
        { question: 'Lettre de confirmation de RP', answer: formData.step7.letterRP },
      ]
    }
  ];

  return (
    <div>
      <h2>Récapitulatif des Informations</h2>
      {steps.map((step, index) => (
        <div key={index} className="mt-4">
          <h4>{step.title}</h4>
          <ul>
            {step.questions.map((q, idx) => (
              <li key={idx}>
                <strong>{q.question} :</strong> {q.answer}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Review;
