import React, { useState, useEffect } from "react";
import { FaBell } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';

const Calendar = ({ events }) => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [highlightedDates, setHighlightedDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null); // État pour l'événement sélectionné
  const [notification, setNotification] = useState(false);

  useEffect(() => {
    // Récupérer les dates avec événements
    const dates = events.map(event => new Date(event.date).getDate());
    setHighlightedDates(dates);

    // Vérifier s'il y a des événements proches (par exemple, dans les 3 prochains jours)
    const today = new Date();
    const upcomingEvent = events.find(event => {
      const eventDate = new Date(event.date);
      return eventDate >= today && eventDate <= new Date(today.getTime() + 3 * 24 * 60 * 60 * 1000);
    });

    if (upcomingEvent) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  }, [events]);

  const getDaysInMonth = (month, year) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const handleDayClick = (day) => {
    const event = events.find(event => new Date(event.date).getDate() === day);
    setSelectedEvent(event || null); // Mettre à jour l'événement sélectionné
  };

  const renderDays = () => {
    const daysInMonth = getDaysInMonth(currentDate.getMonth(), currentDate.getFullYear());
    const days = [];

    for (let day = 1; day <= daysInMonth; day++) {
      const isHighlighted = highlightedDates.includes(day);
      days.push(
        <div
          key={day}
          className={`col-1 p-2 border text-center ${isHighlighted ? 'bg-warning' : ''}`}
          onClick={() => handleDayClick(day)} // Ajout de l'événement de clic
          style={{ cursor: isHighlighted ? 'pointer' : 'default' }}
        >
          {day}
        </div>
      );
    }
    return days;
  };

  const handlePrevMonth = () => {
    const prevDate = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    setCurrentDate(prevDate);
  };

  const handleNextMonth = () => {
    const nextDate = new Date(currentDate.setMonth(currentDate.getMonth() + 1));
    setCurrentDate(nextDate);
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <button className="btn btn-custom" onClick={handlePrevMonth}>
          Précédent
        </button>
        <h3>{currentDate.toLocaleString('default', { month: 'long' })} {currentDate.getFullYear()}</h3>
        <div>
          {notification && (
            <FaBell className="text-danger" />
          )}
          <button className="btn btn-custom ml-3" onClick={handleNextMonth}>
            Suivant
          </button>
        </div>
      </div>

      <div className="row">
        {renderDays()}
      </div>

      {/* Section pour afficher les détails de l'événement sélectionné */}
      {selectedEvent && (
        <div className="mt-4 p-3 border bg-light">
          <h4>Détails de l'événement</h4>
          <p><strong>Date:</strong> {new Date(selectedEvent.date).toLocaleDateString()}</p>
          <p><strong>Description:</strong> {selectedEvent.description}</p>
        </div>
      )}
    </div>
  );
};

export default Calendar;
