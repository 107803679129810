import React, { useState } from 'react';

const Step7 = ({ data, updateData }) => {
  const [form, setForm] = useState(data);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
    updateData({ ...form, [name]: value });
  };

  return (
    <form>
      <div className="mb-3">
        <label className="form-label">Comment avez-vous entendu parler de nous ?</label>
        <input
          type="text"
          className="form-control"
          name="source"
          value={form.source || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Loyer maximum souhaitable</label>
        <input
          type="number"
          className="form-control"
          name="loyer_max"
          value={form.loyer_max || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Préférence de langue</label>
        <input
          type="text"
          className="form-control"
          name="langue_preference"
          value={form.langue_preference || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Votre niveau d'anglais (sur une échelle de 1 à 10)</label>
        <input
          type="number"
          className="form-control"
          name="niveau_anglais"
          min="1"
          max="10"
          value={form.niveau_anglais || ''}
          onChange={handleChange}
        />
      </div>

      <div className="mb-3">
        <label className="form-label">Téléchargez votre lettre de confirmation de RP</label>
        <input
          type="file"
          className="form-control"
          name="lettre_rp"
          onChange={handleChange}
        />
      </div>
    </form>
  );
};

export default Step7;
