import React, { useState } from 'react';
import './assests/Dashboard.css';
import ProcessPieChart from './ProcessPieChart';
import ServiceBarChart from './ServiceBarChart';
import Sidebar from './SideBar';
import Header from './Header';
import Checklist from './Checklist';
import Calendar from './Calendar';

const Logements = () => {
    const [isSidebarOpen, setSidebarOpen] = useState(false);
  
    const toggleSidebar = () => {
      setSidebarOpen(!isSidebarOpen);
    };
    const logementProcesses = [
        { name: 'Recherche du logement', status: 'Terminé' },
        { name: 'Visite virtuelle des logements', status: 'Engagé' },
        { name: 'Finalisation du contrat de location', status: 'En retard' },
        { name: 'Préparation pour l’emménagement', status: 'Engagé' },
      ];
      const events = [
        { date: '2024-10-01', title: 'Échéance pour la réservation du vol' },
        { date: '2024-10-05', title: 'Assurance santé à confirmer' },
        { date: '2024-10-10', title: 'Consultation avec le conseiller' },
      ];
      return (
        <div className="wrapper">
            <div className="flex">
                    {/* Sidebar */}
                <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    
                {/* Main Content */}
                <div className="contente">
                    {/* Header avec le bouton Hamburger */}
                    <Header pageTitle="Logements" username="username" toggleSidebar={toggleSidebar} />
    
                    {/* Dashboard Content */}
                    <div className="p-3">
                        <div className="row mb-4">
                            <div className="col-lg-4 col-md-4 col-sm-6">
                            <div className="card">
                                <div className="p-2">
                                <h5>État des Processus</h5>
                                <h4 className="card-text">4 SERVICES</h4>
                                <ProcessPieChart />
                                </div>
                            </div>
                            </div>
                            <div className="col-lg-8 col-md-8 col-sm-6">
                            <div className="card">
                                <div className="p-2">
                                <h5 className="card-title">Statistique 2</h5>
                                <ServiceBarChart />
                                </div>
                            </div>
                            </div>
                        </div>
                         {/* Process Cards */}
                        <div className="row d-flex">
                            <Checklist            
                                processes={logementProcesses}
                            />
                            <div className="col-md-6">
                                <Calendar events={events}/>
                            </div>
                            
                       
                        </div>
                 </div>
             </div>
            </div>
        </div>
  );
};

export default Logements;